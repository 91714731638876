import { MenuItem, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import CallAddIcon from '../../../../../icons/CallAddIcon';
import CallEndIcon from '../../../../../icons/CallEndIcon';
import { CallResponse, CallState, CallStatusResponse, CallStatusToStateMap, useAppState } from '../../../../../state';
import { ServiceEndpoint } from '../../../../../state/requests';
import { IconContainer } from '../../Menu';
import PhoneNumberSelectionDialog from '../CallPatient/PhoneNumberSelectionDialog';

export default function CallTranslatorButton() {
  const { translatorCallInfo, setTranslatorCallInfo, makeServiceRequest } = useAppState();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { callState, callSid } = translatorCallInfo;

  const makeCall = async (phoneNumber: string) => {
    setOpenDialog(false);
    setTranslatorCallInfo({
      callSid: '',
      callState: CallState.Calling,
    });

    const callPatientResponse = await makeServiceRequest({
      path: ServiceEndpoint.AddPatient,
      body: {
        phoneNumber: phoneNumber,
      },
    });

    if (callPatientResponse.ok) {
      const responseBody: CallResponse = await callPatientResponse.json();
      setTranslatorCallInfo({
        callSid: responseBody.callSid,
        callState: CallState.Calling,
      });
    } else {
      setTranslatorCallInfo({
        callSid: '',
        callState: CallState.NoCall,
      });
    }
  };

  const disconnectCall = async () => {
    await makeServiceRequest({
      path: ServiceEndpoint.RemoveCall,
      body: {
        callSid: callSid,
      },
    });

    setTranslatorCallInfo({
      callSid: '',
      callState: CallState.NoCall,
    });
  };

  useEffect(() => {
    const timer = setInterval(async () => {
      if (callState === CallState.NoCall || callSid === '') return;

      const callTranslatorResponse = await makeServiceRequest({
        path: ServiceEndpoint.CallStatus,
        body: {
          callSid: callSid,
        },
      });
      const responseBody: CallStatusResponse = await callTranslatorResponse.json();
      const newState = CallStatusToStateMap[responseBody.callStatus];
      if (newState === callState) return;

      setTranslatorCallInfo({
        callSid: callSid,
        callState: newState,
      });
    }, 5000);
    // Clear timeout if the component is unmounted
    return () => clearInterval(timer);
  }, [translatorCallInfo]);

  let onClick = () => setOpenDialog(!openDialog);
  let Icon: ReactElement = <CallAddIcon />;
  let callLabel: string = '';
  let endCallLabel: string = '';
  if (callState === CallState.Connected) {
    callLabel = 'Connected';
    endCallLabel = 'Disconnect Translator';
  } else if (callState === CallState.Calling) {
    Icon = <CallAddIcon />;
    callLabel = 'Calling...';
    endCallLabel = 'Cancel Call';
  } else if (callState === CallState.NoCall) {
    Icon = <CallAddIcon />;
    callLabel = 'Call Translator';
  }

  return (
    <>
      <MenuItem onClick={onClick} disabled={callState === CallState.Calling}>
        <IconContainer>{Icon}</IconContainer>
        <Typography variant="body1">{callLabel}</Typography>
      </MenuItem>
      {callState !== CallState.NoCall ? (
        <MenuItem onClick={disconnectCall}>
          <IconContainer>
            <CallEndIcon />
          </IconContainer>
          <Typography variant="body1">{endCallLabel}</Typography>
        </MenuItem>
      ) : null}
      <PhoneNumberSelectionDialog open={openDialog} onCancel={() => setOpenDialog(false)} onConfirm={makeCall} />
    </>
  );
}
