import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { Typography, makeStyles, TextField, Theme, Switch, useMediaQuery } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// import BookingDetails from '../../IntroContainer/UserMenu/bookingDetails/BookingDetails';
import styles from './RoomNameScreen.module.css';
import EngLogo from '../../../assets/eng-logo.svg';
import FrLogo from '../../../assets/fr-logo.svg';

import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    fontSize: '34px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      marginBottom: '1.5rem',
    },
  },

  inputContainer: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 1em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
  },

  consentTerm: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'start',

    paddingTop: '5px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginTop: '.5rem',
    },
  },

  consentText: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    marginTop: '0px',
  },

  checkbox: {
    height: '35px',
    width: '35px',
    marginTop: '-5px',
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      width: '25px',
      marginTop: '-5px',
      marginRight: '15px',
    },
  },

  privacyPolicy: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 600,
    color: 'black',
    marginLeft: '5px',
  },

  textFieldContainer: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    width: '100%',
  },

  hostButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '70px',
    marginBottom: '-45px',
    background: 'none',
    border: 'none',
    fontSize: '10px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      fontSize: '16px',
      width: '100%',
      marginTop: '25px',
      marginBottom: '-20px',
    },
  },

  continueButton: {
    fontSize: '20px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    padding: '10px',

    background: '#2D67BA',
    color: '#fff',

    border: 'none',
    borderRadius: '43.5px',

    boxShadow: ' 0px 8px 13px 3px rgba(0, 0, 0, 0.25)',

    width: '160px',

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      width: '30%',
      marginTop: '-20px',
      fontSize: '18px',
    },
    float: 'right',
  },

  hostKeyText: {
    position: 'relative',
    bottom: '-7px',
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
      fontSize: '16px',
      marginTop: '5px',
    },
  },
  bookingDetails: {
    backgroundColor: '#78aq45',
    color: 'white',
    lineHeight: '15px',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    position: 'absolute',
    left: '30px',
    bottom: '20px',
    width: '300px',
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setConsent: (name: boolean) => void;
  setName: (name: string) => void;
  setHostKey: (hostKey: string) => void;
  handleSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function RoomNameScreen({ name, setName, setConsent, handleSubmit, setHostKey }: RoomNameScreenProps) {
  const classes = useStyles();
  const [useHost, setUseHost] = useState<boolean>(false);
  const [inputHostKey, setInputHostKey] = useState<string>('');
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [recordingIsAble, setRecordingIsAble] = useState(false);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleHostKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputHostKey(event.target.value);
    setHostKey(event.target.value);
  };

  const toggleHost = () => {
    if (useHost) {
      setHostKey('');
    } else {
      setHostKey(inputHostKey);
    }

    setUseHost(!useHost);
  };

  const handleConsent = () => {
    setRecordingIsAble(!recordingIsAble);
    setConsent(!recordingIsAble);
    console.log('room', recordingIsAble);
  };

  return (
    <>
      <div className={styles.title}>
        {lang === 'fr' ? (
          <img className={styles.LogoContainer} src={FrLogo} alt="Logo" />
        ) : (
          <img className={styles.LogoContainer} src={EngLogo} alt="Logo" />
        )}
      </div>
      <div className={styles.InputContainer}>
        <div>
          <TextField
            id="input-user-name"
            variant="outlined"
            fullWidth
            size="small"
            value={name}
            onChange={handleNameChange}
            placeholder={lang === 'fr' ? 'Votre nom' : 'Your name'}
          />
        </div>
        {useHost ? (
          <>
            <div>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={inputHostKey}
                onChange={handleHostKeyChange}
                placeholder="Host key"
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.ConsentTerm}>
              <input className={styles.checkBox} type="checkbox" onChange={handleConsent} />
              <p>
                <FormattedMessage id="consent" />
              </p>
            </div>
          </>
        )}
      </div>
      <div className={styles.ButtonsContainer}>
        <div className={styles.hostSwitch}>
          <Switch color="primary" checked={useHost ? true : false} onChange={toggleHost} />
          <span className={styles.hostText}>
            <FormattedMessage id="join_as_host" />
          </span>
        </div>

        <button
          disabled={useHost ? !inputHostKey || !name : !name}
          className={styles.continueButton}
          onClick={e => {
            handleSubmit(e);
          }}
        >
          <FormattedMessage id="join" />
        </button>
      </div>
    </>
  );
}
