export enum ServiceEndpoint {
  AddTranslator = '/participants/addTranslator',
  RemoveCall = '/participants/removeCall',
  AddPatient = '/participants/addPatient',
  Login = '/token',
  GetWaitTime = '/averageWaitInSecs',
  StartHold = '/rooms/putPatientOnHold',
  FinishHold = '/rooms/resumeOnHold',
  CallStatus = '/participants/getCallInfo',
  EndCall = '/rooms/endCall',
  SyncState = '/rooms/syncState',
}

type AddTranslatorRequest = {
  path: ServiceEndpoint.AddTranslator;
  body?: undefined;
};
type AddPatientRequest = {
  path: ServiceEndpoint.AddPatient;
  body: {
    phoneNumber: string;
  };
};
type LoginRequest = {
  path: ServiceEndpoint.Login;
  body: {
    flexToken?: string;
  };
};
type WaitTimeRequest = {
  path: ServiceEndpoint.GetWaitTime;
  body?: undefined;
};
type StartHold = {
  path: ServiceEndpoint.StartHold;
  body?: undefined;
};
type FinishHold = {
  path: ServiceEndpoint.FinishHold;
  body?: undefined;
};
type GetCallStatus = {
  path: ServiceEndpoint.CallStatus;
  body: {
    callSid: string;
  };
};
type RemoveCall = {
  path: ServiceEndpoint.RemoveCall;
  body: {
    callSid: string;
  };
};

type EndCall = {
  path: ServiceEndpoint.EndCall;
  body: {
    callDispositionStatus: string;
    travellerCount: number;
  };
};

type SyncState = {
  path: ServiceEndpoint.SyncState;
  body?: undefined;
};

export type ServiceRequest =
  | AddTranslatorRequest
  | AddPatientRequest
  | LoginRequest
  | WaitTimeRequest
  | StartHold
  | FinishHold
  | GetCallStatus
  | RemoveCall
  | EndCall
  | SyncState;
