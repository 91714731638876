import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useParticipants from '../../hooks/useParticipants/useParticipants';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user, roomAndHostKeyValid, setRoomAndHostKeyValid, validateRoomAndHostKey } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [consent, setConsent] = useState<boolean>(false);
  const [roomName, setRoomName] = useState<string>('');
  const [hostKey, setHostKey] = useState<string>('');
  const [handleSubmitClicked, setHandleSubmitClicked] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const queryName = queryParams.get('name');
    if (!name && queryName) setName(queryName);

    const queryRoom = queryParams.get('room');
    if (!roomName && queryRoom) setRoomName(queryRoom);
  }, [queryParams, setRoomName, setName, name, roomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    if (handleSubmitClicked) {
      console.log('Validating room and host key');
      validateRoomAndHostKey(name, roomName, hostKey);
      setHandleSubmitClicked(false);
    }

    if (roomAndHostKeyValid) {
      console.log('Room and host key are valid');
      setStep(Steps.deviceSelectionStep);
      setHandleSubmitClicked(false);
      setRoomAndHostKeyValid(false);
    }
  }, [roomAndHostKeyValid, validateRoomAndHostKey, roomName, hostKey, setRoomAndHostKeyValid, handleSubmitClicked]);

  const handleSubmit = () => {
    setHandleSubmitClicked(true);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          setConsent={setConsent}
          roomName={roomName}
          setName={setName}
          setHostKey={setHostKey}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} hostKey={hostKey} consent={consent} />
      )}
    </IntroContainer>
  );
}
